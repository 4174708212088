<template>
  <component
    :is="item.link ? 'base-link' : 'span'"
    :link="item.link ? item.link.url : null"
    :target="item.link ? item.link.target : null"
    class="m-collage-item"
    :class="`-${variant}`"
  >
    <base-image
      v-if="item.image && item.image[0]"
      :url="item.image[0].Url"
      :width="676"
      :height="variant === 'primary' ? 580 : 278"
      :ratio="variant === 'primary' ? 676/580 : 676/278"
      :alt-text="item.imageAltText"
      image-class="m-collage-item__image"
    />
    <div
      v-if="item.firstPublishDate"
      class="m-collage-item__date"
    >
      {{ publishDate }}
    </div>
    <span class="m-collage-item__overlay">
      <h2 class="m-collage-item__headline" :class="{'h1': variant === 'primary'}">{{ item.headline }}</h2>
      <span class="m-collage-item__subline">{{ item.subline }}</span>
      <span v-if="item.link && item.link.name" class="m-collage-item__cta">
        {{ item.link.name }}
        <base-icon v-if="variant !== 'primary'" icon="chevron-right" />
      </span>
    </span>
  </component>
</template>


<script>
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/da';
import 'dayjs/locale/sv';
import BaseIcon from '@/components/atoms/BaseIcon'
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';

export default {
  name: 'CollageItem',
  components: {
    BaseLink,
    BaseImage,
    BaseIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    culture () {
      return this.$store.getters['content/content/getContent']?.meta?.culture;
    },
    publishDate () {
      let culture = this.culture.split('-')[0];
      let date = dayjs(this.item.firstPublishDate).locale(culture).format('dddd [d.] D/M - YYYY');
      return date;
    }
  }
}
</script>
