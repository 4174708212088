<template>
  <collage :primary-item="content.items[2]" :secondary-items="secondaryItems" />
</template>


<script>
import contentMixin from '@/mixins/contentMixin';
import Collage from '@/components/molecules/Collage';

export default {
  name: 'CollageManual',
  components: {
    Collage
  },
  mixins: [contentMixin],
  computed: {
    secondaryItems () {
      return this.content.items.slice(0, 2);
    }
  }
}
</script>
