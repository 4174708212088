<template>
  <div class="m-collage -hero">
    <div class="container">
      <div class="row">
        <div v-if="primaryItem" class="col-lg-6 order-lg-2">
          <collage-item :item="primaryItem" variant="primary" />
        </div>
        <div v-if="secondaryItems && secondaryItems.length > 0" class="col-lg-6 order-lg-1">
          <collage-item v-for="(item, i) in secondaryItems" :key="i" :item="item" variant="secondary" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CollageItem from '@/components/molecules/CollageItem';

export default {
  name: 'Collage',
  components: {
    CollageItem
  },
  props: {
    primaryItem: {
      type: Object,
      default: () => null,
      required: true
    },
    secondaryItems: {
      type: Array,
      default: () => []
    },
  },
}
</script>
